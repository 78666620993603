.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
  backdrop-filter: blur(6px);

  .left {
    .full-name {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.23em;
    }
  }
  .right {
    display: flex;
    flex-direction: row;

    .social-networks {
      display: flex;
      flex-direction: row;
      align-items: center;

      .social-network-item {
        margin-left: 9px;
        margin-right: 9px;
        width: 30px;
        height: 30px;
        object-fit: contain;
        cursor: pointer;
      }
    }
    .menu-icon {
      margin-left: 40px;
      width: 60px;
      object-fit: contain;
      cursor: pointer;
    }
  }
}
