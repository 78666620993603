.project-details {
  position: relative;
  .project-logo {
    position: fixed;
    top: 80px;
    right: 40px;
    height: 550px;
    object-fit: contain;
  }

  .wrapper {
    color: white;
    width: 100%;
    padding: 20px;
    .title {
      font-weight: 700;
      font-size: 64px;
    }
    .subtitle {
      font-weight: 400;
      font-size: 24px;
      margin-bottom: 75px;
    }
    .details-image {
      width: 100%;
      object-fit: contain;
      margin-bottom: 100px;
    }

    .content-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;

      .project-description {
        margin-bottom: 50px;
      }
      .content-title {
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 30px;
      }

      .content {
        display: flex;
        flex-direction: column;

        .content-description {
          font-weight: 400;
          font-size: 24px;
          margin: 7.5px 0;
        }
      }

      .my-responsibility {
        .my-responsibility-items {
          display: flex;
          flex-direction: column;
          .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 100px;
            margin-top: 50px;
            .left {
              margin-right: 75px;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .item-title {
                min-width: 300px;
                font-weight: 700;
                font-size: 180px;
                line-height: 150px;
              }
              .item-subtitle {
                font-weight: 700;
                font-size: 24px;
                margin-right: 14px;
              }
            }
            .right {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}
