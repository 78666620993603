.layout {
  .background {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    pointer-events: none;
  }
}

.main-menu {
  .main-menu-wrapper {
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .main-menu-items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      .item {
        margin: 20px 0;
        font-size: 48px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
      }
      a {
        overflow: hidden;
        position: relative;
        display: inline-block;
      }

      a::before,
      a::after {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
      }
      a::before {
        background-color: #54b3d6;
        height: 2px;
        bottom: 0;
        transform-origin: 100% 50%;
        transform: scaleX(0);
        transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
      }
      a::after {
        content: attr(data-replace);
        height: 100%;
        top: 0;
        transform-origin: 100% 50%;
        transform: translate3d(200%, 0, 0);
        transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
        color: #54b3d6;
      }

      a:hover::before {
        transform-origin: 0% 50%;
        transform: scaleX(1);
      }
      a:hover::after {
        transform: translate3d(0, 0, 0);
      }

      a span {
        display: inline-block;
        transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
      }

      a:hover span {
        transform: translate3d(-200%, 0, 0);
      }

      a {
        text-decoration: none;
        color: white;
        font-weight: 700;
        vertical-align: top;
      }
    }
    .social-networks {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .icon {
        font-size: 36px;
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
}
