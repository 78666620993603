.homepage {
  .homepage-wrapper {
    max-width: 1670px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
    color: white;

    .anchor-section-two {
      display: block;
      position: relative;
      top: -150px;
      visibility: hidden;
    }

    .section-one,
    .section-two,
    .section-three,
    .section-four {
      margin-bottom: 300px;
    }

    .section-two,
    .section-three,
    .section-four {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .section-title {
      font-weight: 700;
      font-size: 84px;
      line-height: 105px;
    }

    .section-sub-title {
      font-weight: 200;
      font-size: 18px;
      letter-spacing: 0.1em;
    }

    .lines {
      margin-top: 50px;
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;

      .line-one,
      .line-two {
        width: 121px;
        height: 6px;
        background: #33ff00;
        border-radius: 50px;
      }

      .line-two {
        margin-left: 40px;
        margin-top: 20px;
      }
    }

    .section-description {
      font-weight: 200;
      font-size: 36px;
      line-height: 45px;
      letter-spacing: 0.1em;
      margin-bottom: 120px;
    }
    .explore {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 700;
      font-size: 24px;
      letter-spacing: 0.1em;
      text-decoration: inherit;
      color: white;
      cursor: pointer;
      .arrow {
        width: 57px;
        object-fit: contain;
        margin-right: 20px;
      }
      .arrow-right {
        width: 57px;
        object-fit: contain;
        margin-left: 20px;
        transform: rotate(-90deg);
      }
    }

    .section-two {
      .section-two-left {
        .technologies {
          margin-top: 64px;
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            margin-right: 22px;
            height: 30px;
            object-fit: contain;
          }
        }
      }
      .section-two-right {
        .project-sitesmart {
          width: 1100px;
          object-fit: contain;
          box-shadow: 0px 4px 20px 7px rgba(0, 0, 0, 0.6);
          border-radius: 20px;
        }
      }
    }

    .section-three {
      .section-three-right {
        .about-image {
          width: 1000px;
          object-fit: contain;
          box-shadow: 0px 4px 20px 7px rgba(0, 0, 0, 0.6);
          border-radius: 20px;
        }
      }
    }
    .section-four {
      .section-four-left {
        .social-networks {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: -50px;

          img {
            margin-right: 20px;
            width: 30xp;
            object-fit: contain;
            cursor: pointer;
          }
        }
      }
      .section-four-right {
        .about-image {
          width: 1080px;
          object-fit: contain;
          box-shadow: 0px 4px 20px 7px rgba(0, 0, 0, 0.6);
          border-radius: 20px;
        }
      }
    }
  }
}
